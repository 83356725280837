const cookieBox = document.querySelector('.cookie-box');
const cookieBtn = document.querySelector('.cookie-btn');
const msgStatus = document.querySelector('.msg-status');
const partnersLogos = document.querySelector('.stripeContent');
const partnersSlider = document.querySelector('.bottomStripe');
const offertBoxs = document.querySelector('.logos-slide');
const offertSlider = document.querySelector('.logos');
const copyrightsYear = document.querySelector('.date');
const form = document.querySelector('.contactForm');
const formName = document.querySelector('#name');
const formEmail = document.querySelector('#email');
const politicsCheckbox = document.querySelector('#checkPolitics');
const checkboxErr = document.querySelector('.checkboxPolitics');
const formMsg = document.querySelector('#msg');
const formBtn = document.querySelector('.send');
const fadeIn = document.querySelectorAll('.fadeIn');
const abtRight = document.querySelector('.abtRight');

//Zmiana zdjęć w aboutus
const images = ['bg1', 'bg2', 'bg3', 'bg4', 'bg5'];
let current = 0;

function changeBackground() {
	abtRight.classList.remove(images[current]);
	current = (current + 1) % images.length;
	abtRight.classList.add(images[current]);
}

// Zmieniaj tło co 6 sekund (czas trwania animacji + czas na przejrzystość)
setInterval(changeBackground, 6000);

//Efekt pojawiania sie tekstu
const isInView = (element) => {
	const rect = element.getBoundingClientRect();
	const windowHeight =
		window.innerHeight || document.documentElement.clientHeight;
	const offset = windowHeight * 0.2;
	return rect.bottom > 0 && rect.top < windowHeight - offset;
};

//Cookie Alert
const showCookie = () => {
	const cookieEaten = localStorage.getItem('cookie');
	if (cookieEaten) {
		cookieBox.classList.add('hide');
	}
};

const handleCookieBox = () => {
	localStorage.setItem('cookie', 'true');
	cookieBox.classList.add('hide');
};

//Walidacja Formularza
const showError = (input, msg) => {
	const formBox = input.nextElementSibling;
	formBox.textContent = msg;
	formBox.classList.add('errorVisible');
};

const clearError = (input) => {
	const formBox = input.nextElementSibling;
	formBox.classList.remove('errorVisible');
};

const handleForm = (input) => {
	input.forEach((el) => {
		if (el.value === '') {
			showError(el, el.placeholder);
		} else {
			clearError(el);
		}
	});
};

const checkMail = (email) => {
	const re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
	if (re.test(email.value)) {
		clearError(email);
	} else {
		showError(email, 'Podany adres jest niepoprawny');
	}
};

const checkPolitics = (checkbox) => {
	const formError = checkboxErr.lastElementChild;
	if (!checkbox.checked) {
		formError.textContent = 'To pole jest wymagane!';
		formError.classList.add('errorVisible');
	} else {
		formError.textContent = '';
		formError.classList.remove('errorVisible');
	}
};

const checkErrors = () => {
	const allErrors = document.querySelectorAll('.errorForm');
	let errorCount = 0;

	allErrors.forEach((el) => {
		if (el.classList.contains('errorVisible')) {
			errorCount++;
		}
	});

	if (errorCount === 0) {
		form.submit();
		if (document.location.search === '?mail_status=sent') {
			msgStatus.classList.add('success');
			msgStatus.textContent = 'Wiadomość wysłana!';
			setTimeout(() => {
				msgStatus.classList.remove('success');
			}, 3000);
		} else if (document.location.search === '?mail_status=error') {
			msgStatus.classList.add('error');
			msgStatus.textContent = 'Wystąpił błąd!';
			setTimeout(() => {
				msgStatus.classList.remove('error');
			}, 3000);
		}
	}
};

//Petla wykonująca kopię logo partnerów
const partnersCopy = () => {
	let copy;
	for (i = 1; i < 4; i++) {
		copy = partnersLogos.cloneNode(true);
		partnersSlider.appendChild(copy);
	}
};

//Petla wykonująca kopię box'ów oferty
const offertCopy = () => {
	let copy;
	for (i = 1; i < 4; i++) {
		copy = offertBoxs.cloneNode(true);
		offertSlider.appendChild(copy);
	}
};

//Automatyczna aktualizacja / Aktualna data(rok) w prawach autorskich
const newYear = () => {
	const year = new Date();
	copyrightsYear.textContent = year.getFullYear();
};

//Wywołania
document.addEventListener('scroll', () => {
	fadeIn.forEach((fade) => {
		if (isInView(fade)) {
			fade.classList.add('fadeIn--visible');
		}
	});
});
formBtn.addEventListener('click', (e) => {
	e.preventDefault();
	handleForm([formName, formEmail, formMsg]);
	checkMail(formEmail);
	checkPolitics(politicsCheckbox);
	checkErrors();
});
cookieBtn.addEventListener('click', handleCookieBox);
showCookie();
newYear();
partnersCopy();
offertCopy();
